// common styles or shared styles 


$color1: #CCCC00;
$color2: #3A6891;


// Buttons

.cta {
  & { background-color: $color1; color: #FFFFFF; padding: 10px 15px; font-family: $fontRaleway; font-size: 21px; font-weight: 700; border-radius: 5px; text-align: center; text-decoration: none; text-transform: none; }
  &:hover { background-color: $color2; }
}


// Forms 







// Slider Styles 







