header {
  & { background-color: #293745; }
  // .header-wrapper { display: flex; align-items: center; justify-content: space-between; height: 126px; max-width: 1100px; width: 100%; margin: 0 auto; }
  // .header-wrapper { margin: 0 auto; max-width: 1110px; height: min-content; display: flex; justify-content: space-between; align-items: center; padding: 3rem 1rem 1rem 1rem; }
  .header-wrapper { margin: 0 auto; max-width: 1000px; width: 96%; height: 126px; display: flex; align-items: center; padding: 0 15px; box-sizing: border-box; justify-content: space-between; }

  // .logo { 
  //   // & { position: absolute; left: 50px; cursor: default; }
  //   & { width: 262px; display: flex; align-items: center; justify-content: center; margin: 0 auto; }
  //   img { max-height: 60px; max-width: 200px; padding: 0 5px; }
  // }
  // .assessment-title { 
  //   & { flex: 1 1 auto; text-align: center; color: $color3; }
  //   .logo-text { font-size: 32px; margin: 0 auto; font-weight: 700; line-height: 43px; }
  //   .logo-subtext { font-size: 21px; margin: 0 auto; font-weight: 700; line-height: 25px; letter-spacing: 8px; }
  //   img { height: 29px; max-width: 433px; margin-top: 5px; } 
  // }
  // .bleat-logo { position: absolute; right: 50px; }
  // .logo-left { & { }
  //   a { }
  //   img { width: 240px; max-width: 192px; height: 62px;justify-content:flex-start; }
  // }

  .assessment-title { & { max-width: 399px; width: 90%; text-align: center; }
     img { width: 100%; }
    .logo-text { font-family: $fontLato; font-size: 36px; font-weight: 700; line-height: 31px; letter-spacing: 1px; margin: 0 10px; }
    .logo-text span { color: #FFFFFF; }
    .logo-subtext { font-family: $fontLato; color: #FFFFFF; font-size: 21px; font-weight: 700; line-height: 25px; letter-spacing: 8px; text-transform: uppercase; margin: 10px auto 0; }
  }
  .logo-left { & { width: 100%; max-width: 200px; display: flex; justify-content: flex-start; align-items: center; }
    img { width: 70%; max-width: 200px; height: auto; }
  }
  .logo-right { width: 100%; min-width: 85px; max-width: 200px; text-align: right; display: flex; justify-content: flex-end; align-items: center; }
}


@media only screen and (max-width: 1250px) {

  header {
    .logo-right { margin-left: 0; }
    // .bleat-logo { right: 50px; }
  }

}

@media only screen and (max-width: 830px) {

  header { & { height: auto;  // flex-direction: column; align-items: flex-start; 
    }
    .header-wrapper { display: flex; min-height: 85px; padding: 10px 15px; box-sizing: border-box; }
    .logo-left { & { width: 40px; max-width: 40px; height: auto; }
      img { width: auto; height: 42px; }
    }
    .logo-right { min-width: 32px; width: 34px; max-width: 34px; display: none; }
    // .logo-right { position: relative; margin: 0 auto; left: 0; margin: 20px auto; padding: 0 0 0 10px; width: auto; min-width: auto; }
    // .assessment-title { margin: 10px auto; 
    //   .logo-text { font-size: 28px; line-height: 42px; }
    //   .logo-subtext { font-size: 20px; line-height: 25px; letter-spacing: 4px; }
    // }
    // .bleat-logo { display: none; }
    .assessment-title { margin: 10px auto; 
      .logo-text { font-size: 28px; line-height: 30px; }
      .logo-subtext { font-size: 20px; line-height: 21px; letter-spacing: 4px; }
    }
  }

}


@media only screen and (max-width: 650px) {


  header { 
    .header-wrapper { display: grid; grid-template-columns: 0.12fr 1fr 0.12fr; padding: 15px 0px; height: auto; }
    // .header-wrapper { display: grid; grid-template-columns: 80px 1fr 80px; height: auto; }
    .logo-left { & { text-align: center; align-items: center; width: 60px; max-width: 60px; }
      img { padding: 5px; height: 50px; }
    }
    .assessment-title { & { margin: 0px auto; width: 95%; max-width: 250px; }
      img { height: auto; width: 90%; }
      .logo-text { font-size: 24px; line-height: 28px }
      .logo-subtext { font-size: 14px; line-height: 16.8px;  font-weight: 700; letter-spacing: 5px;}}
      .left-logo img { width: 39px; height:39px; }

  }

}

@media only screen and (max-width: 340px) {

  header .logo-left { width: 100%; height: auto; max-width: 200px; justify-content: center; }
  header .header-wrapper { display: flex; flex-direction: column; }
  header .assessment-title {
    .logo-text { font-size: 24px; line-height: 28px; font-weight: 700; }
  }

}

@media only screen and (max-width: 300px) {
  
  header .assessment-title .logo-text { font-size: 21px; line-height: 24px; margin-left: 0; margin-bottom: 5px; }

}






