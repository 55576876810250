

.container.pre-assessment { max-width: none; width: 100%; align-items: center; margin: 0; }
.pre-assessment { 
  & { color: $color2; max-width: 65ch; margin: 0px auto 15px; padding: 15px; font-family: $primaryfont; display: flex; flex-direction: column; justify-content: center; }
  h1 { font-size: 30px; font-weight: 400; font-family: $fontPTSerif; line-height: 32px; text-align: center; color: #747474; max-width: 70ch; margin: 15px auto; }
  h2 { 
    & { font-weight: 400; text-align: center; }
    span { font-weight: 600; color: $color2; }
  }
  p { color: #696969; font-size: 18px; line-height: 25px; text-align: center; font-weight: 400; font-family: $fontPTSerif; }
  .pre-assessment-subheader { font-weight: 600; text-align: center; font-family: $primaryfont; font-size: 18px; }
  .explanation {
    & { display: flex; flex-direction: row; justify-content: center; align-items: center; font-family: $primaryfont; font-size: 18px; }
    p { font-weight: 500; display: block; }
    .marker { box-sizing: border-box; flex: 0 0 30px; height: 30px; width: 100%; border-radius: 50%; background-color: white; margin: 10px 20px; }
    .red { border: 5px solid #FF2121; }
    .yellow { border: 5px solid #FDBF04; }
    .green { border: 5px solid #73B76F; }
  }
  .cta-link { text-decoration: none; margin: 30px auto 15px; }
  .cta { font-family: $primaryfont; color: #fff; display: block; margin: 45px auto; width: 100%; max-width: 280px; text-align: center; background-color: #3A6891; border-radius: 4px; font-weight: 700; font-size: 21px; letter-spacing: 1px; padding: 5px 15px; text-transform: none; }
  .cta:hover { background-color: #CBCB00; }

  .img-text-row {
    & { display: flex; flex-direction: row; justify-content: center; width: 100%; max-width: 1400px; box-sizing: border-box; flex-wrap: wrap; }
    .img-text-wrapper { 
      & { box-sizing: border-box; width: 19%; max-width: 280px; padding: 15px 10px; margin: 0 auto; min-height: 450px; display: flex; justify-content: space-between; flex-direction: column; background-color: #6183B400; box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25); }
      &:hover { background-color: #bfdcea;  transition: .5s; }
      .img-label { font-size: 24px; font-weight: 700; line-height: 35px; margin: 10px auto; font-family:$primaryfont;color:#747474; }
      img { width: 275px; }
      .text { display: flex; flex-direction: column; justify-content: center; align-items: center; }
      .img { min-height: 380px; display: flex; justify-content: center; align-items: center; }
    }
  }
}



@media screen and (max-width: 1100px) {
  .pre-assessment .img-text-row .img-text-wrapper img { width: 100%; }
}


@media screen and (max-width: 830px) {
  .pre-assessment .img-text-row .img-text-wrapper { width: 40%; margin: 15px auto; }
  .pre-assessment .img-text-row .img-text-wrapper img { min-width: 200px; }
}


@media screen and (max-width: 650px) {
  .pre-assessment h1 { font-size: 24px; }
  .pre-assessment .img-text-row .img-text-wrapper { width: 85%; }
  .pre-assessment .img-text-row .img-text-wrapper img { min-width: 200px; }
}
