
.outro {
  & { max-width: 1150px; display: flex; flex-direction: column; justify-content: center; align-items: center; }
  .heading { max-width:927px; width: auto; padding: 0 15px; color: #343333; font-family: $fontLato; font-size: 36px; font-weight: 700; line-height: 44px; letter-spacing: 0px; text-align: center; }
  .sub-heading { max-width: 1040px; width: auto; font-family: $fontLato; font-size: 24px; font-weight: 400; line-height: 44px; letter-spacing: 0px; text-align: center; margin: 0 auto; padding: 0 25px; }
  .wide-card {
    & { max-width: 1016px; width: 95%; margin: auto; height: auto; border-radius: 10px; background: #FFF; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); display:flex; margin-top: 55px; transition: all 0.25s; }
    .img-container {
      & { width: 50%; }
    img { border-radius: 10px 0 0 10px; object-fit: cover; max-width: 100%; height: 100%; }
    }
    .alpha-items {
      & { display: flex; flex-direction: column; align-items: start; padding: 35px; width: 50%; box-sizing: border-box; }
      h1 { color: #343333; font-family: $fontLato; font-size: 30px; font-weight: 700; line-height: 44px; letter-spacing: 0px; text-align: left; }
      p { color: #343333; font-family: $fontLato; font-size: 18px; font-weight: 400; line-height: 35px; letter-spacing: 0px; text-align: left; margin: 15px auto; }
    .join-btn { color: #F1F1F1;  height: 50px; min-width: 183px; background-color: #EF8933; text-align: center; font-family: $fontLato; font-size: 21px; font-style: normal; font-weight: 700; line-height: normal;  letter-spacing: 1px;  text-transform: uppercase; border-radius: 25px; margin-top:30px; padding: 0 20px; }
    .join-btn:hover { background-color: #293745; }
    }
  }

  .divider {
    & { display: flex; justify-content: space-between; align-items: center; width: 95%; }
    .vertical-divider { height: 1px; width: 25%; background-color: #343333; }
    p { min-width: 378px; color: #343333; text-align: center; font-family: $fontLato; font-size: 30px; font-style: normal; font-weight: 400; line-height: 38px; }
  }
  
  .options{
    & { max-width: 990px; width: 95%; display: grid; grid-template-columns: repeat(3, 1fr); }
    .card { 
      & { border-radius: 10px; background-color: #FFF; max-width: 100%; margin: 10px; display: flex; flex-direction: column; align-items: center; text-align: center; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); }
      .sm-img-container { 
        & { width: 94%; margin: 10px;}
        img { width: 100%; height: 100%; border-radius: 10px; }
      }
      h3 { color: #343333; text-align: center; font-family: $fontLato; font-size: 21px; font-style: normal; font-weight: 700; line-height: 38px; }
      p { margin: 10px auto; padding: 0 15px; color: #343333; text-align: center; font-family: $fontLato; font-size: 16px; font-style: normal; font-weight: 400; line-height: 24px; }
      .button {  
        & { margin-bottom: 30px; cursor: pointer; margin-top: auto; }
        a { color: #F18132; text-align: center; font-family: $fontLato; font-size: 18px; font-style: normal; font-weight: 700; line-height: 20px; text-decoration-line: underline; }
       }
    } 

    .mid-card {
      & { border-radius: 10px; background-color: #FFF; margin: 10px; display: flex; flex-direction: column; justify-content: space-between; align-items: center; text-align: center; box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); }
      .mid-image { 
        & { width: 100%; max-height: 250px; }
        img { border-radius: 10px 10px 0 0; object-fit: cover; width: 100%; height: 100%; }
      }
      h3 { font-family: $fontLato; font-size: 24px; font-weight: 700; line-height: 38px; letter-spacing: 0px; text-align: center; }
      p { padding: 0 10px; font-family: $fontLato; font-size: 16px; font-weight: 400; line-height: 24px; letter-spacing: 0px; text-align: center; }
      .desc { margin: 0px auto 5px; padding: 0 20px; }
      .button {
        & { padding: 30px 0; }
        a { color: #F18132; text-align: center; font-family: $fontLato; font-size: 18px; font-style: normal; font-weight: 700; line-height: 21px; text-decoration-line: underline; padding: 5px; }
      }
    }
  }

  @media screen and (max-width: 830px) {
    .heading { font-size: 21px; }
    .sub-heading { font-size: 18px; padding: 0 20px; }
    .wide-card { 
      & { flex-direction: column; margin: 10px; width: 90%; }
      .img-container {
        & { width: 100%; }
        img { height: 100%; max-width: 100%; object-fit: cover; width: auto; border-radius: 10px 10px 0 0; }
        
      }
      .alpha-items { 
        & { justify-content: center; text-align: center; align-items: center; width: 90%; margin: 0 auto; padding: 15px 15px 45px 15px; }
        h1 { font-size: 26px; margin: 15px auto; }
        p { text-align: center; }
        .join-btn { margin-top: 15px; }
      }
    }
    .divider {
      .vertical-divider { max-width: 100%; margin: 0 10px; }
      p { font-size: 20px; }
    }
    .options { & { grid-template-columns: repeat(2, 1fr); } }
  }

  @media screen and (max-width: 650px) {
    .heading { max-width: 27ch; margin: 30px auto; text-align: center; line-height: 25px; }
    .wide-card {
      & { padding: 10px; margin: 45px auto 15px; }
      .img-container { 
        & { width: 100%; height: 30%; }
        img { height: 100%; width: 100%; border-radius: 10px; object-fit: contain; }
       }
      
      .alpha-items {
        & { padding: 15px; width: 100%; }
       h1 {margin: 0px auto; padding: 10px auto; font-family: $fontLato; font-size: 21px; font-weight: 700; line-height: 38px; letter-spacing: 0px; text-align: center; }
        p { font-family: $fontLato; font-size: 16px; font-weight: 400; line-height: 24px; letter-spacing: 0px; text-align: center; margin: 20px auto; }
        .join-btn { min-width: 159px; }
      }
    }
    .divider {
      .vertical-divider { max-width: 100%; padding: 0 10px; }
      p { font-size: 16px; min-width: 208px; }
    }
    .options { 
      & { display: flex; flex-direction: column; align-items: center; justify-content: center; } 
      .mid-card {
        & { padding: 10px; }
        .mid-image { & { width: 100%; max-height: unset; }
          img { border-radius: 10px; max-height: auto; width: 100%; object-fit: contain; }
        }
        p { margin: 0px auto 30px; }
        .button p { margin: 15px auto; }
      }
    }
  }
}

