
.MuiMenu-paper.month-select .MuiMenu-list  {
  & { max-height: 150px; }
}

.assessment.loading { height: 589px; }

.assessment {
  & { background-color: #FFF; width: 96%; margin-bottom: 0;min-height:589px;height: auto; }
  .steps {
    & { margin: 0 auto 0; width: 80%; }
    // .spacer { height: 0px; 
    
    .progress-indicator { 
      &{margin: 30px auto 0; width: 100%; font-size: 18px; font-weight: 400; line-height:normal; letter-spacing: 0px; text-align: center; color: #202945;font-family:$fontLato; }
      strong{color: #202945;}
    }

    .question { & { display: flex; justify-content: center; align-items: flex-start; min-height: 120px; max-width: 751px; margin: 0 auto; color: #343333; text-align: center; font-family: $fontLato; font-size: 24px; font-style: normal; font-weight: 400; line-height: 36px; }
      p { margin: 45px auto 0px; font-family: $fontLato; color: #343333; font-size: 24px; font-weight: 400; line-height: 36px; }
    }

    .border-selected { border: 1px solid #293745 !important; }

    .month-year-container { & { display: flex; justify-content: center; flex-direction: column; align-items: center; }
      p { font-size: 16px; font-weight: 700; font-family: $fontLato; color: #343333; margin: 5px 2px; }
      .month-year-select-container { & { display: flex; align-items: center; gap: 14px; justify-content: center; } }
      .not-applicable-container { & { display: flex; } 
        p { font-weight: 400; }
      }
      .MuiInputBase-root { & { background-color: #F3F3F3; width: 100%; border: 1px solid #F3F3F3; cursor: pointer !important; } 
        .MuiOutlinedInput-notchedOutline { border: none; cursor: pointer !important; }
        .MuiSelect-select { padding: 12px 32px 12px 14px; }
        .dropdown-icon { cursor: pointer !important; }
      }
      .month-select { & { min-width: 140px; }
        .MuiSvgIcon-root { cursor: pointer; }
      }
      .year-select { & { min-width: 100px; }
        .MuiSvgIcon-root { cursor: pointer; }
      }
    }

    .question-no-3{
      p{ max-width: 50ch !important; }
    }

    .question-no-4{
      p{ max-width: 32ch !important; }
    }

    .radio-container {
      & { width: 86%; margin: 0 auto; display: flex; align-items: center; min-height: 130px; }
      .slider-labels {
        & { margin: 0 auto; font-weight: 400; font-size: 21px; color: #343333; display: flex; }
        .label {
          & { cursor: pointer; width: 20%; float: left; /* position: relative; */ }
          p { display: block; /* position: absolute; margin-left: -50%; */ margin-top: -20px; padding-top: 20px; width: 100%; text-align: center; z-index: 1000; }
          p.desktop { display: block; }
          p.mobile { display: none; }
        }
        .active { font-weight: 700; color: #ED1B24; }
      }
      .MuiRadio-root { margin: 5px auto; display: flex; width: 30px; height: 30px; }
    }
    .textarea-field { 
      & { max-width: 540px; width: 85%; margin: 0 auto 0; background: #FFFFFF; border: 1px solid #979797; border-radius: 5px; padding: 6px 12px; color: #000; }
      textarea { font-family: $fontLato; font-size: 21px;  font-style: normal;  font-weight: 400;  line-height: normal; color: #000; }
    }
    .slider-container {
      & { width: 86%; margin: 90px auto; }
      .slider { width: 100%; position: relative; }
      .slider-points {
        & { margin-top: -20px; width: 125%; font-weight: 300; font-size: 14px; color: #747474; position: relative; }
        .point {
          & { cursor: pointer; width: 12px; height: 12px; border-radius: 8px; margin-left: -8px; margin-top: -4px; background: #979797; position: absolute; z-index: 9; }
        }
      }
      .slider-labels {
        & { width: 125%; font-weight: 400; font-size: 21px; color: #343333; }
        .label {
          & { cursor: pointer; width: 20%; float: left; position: relative; color: #343333; }
          p { display: block; position: absolute; margin-left: -50%; margin-top: -20px; padding-top: 40px; width: 100%; z-index: 1000; text-align: center; font-family: $fontLato; font-size: 21px; font-style: normal; font-weight: 400; line-height: normal;color: #343333; }
          p.desktop { display: block; }
          p.mobile { display: none; }
        }
        .active p { font-weight: 700; color: #EF8933; }
      }

      .custom-slider.MuiSlider-root { z-index: 1000; color: #EF8933; width: calc(100% + 10px); margin-left: -5px; padding: 12.5px 0; position: absolute; top: 2px; }

      .custom-slider .MuiSlider-rail { opacity: 0; }
      .custom-slider .MuiSlider-track { border: 5px solid #EF8933; position: absolute; top: 0; }

      .custom-slider .MuiSlider-thumb { background-color: #EF8933; padding: 9px; border: 3px solid #EF8933; top: 0; }
      .custom-slider .MuiSlider-thumb:before { background-color: #EF8933; width: 4px; height: 4px; padding: 2px; box-shadow: none; border: 3px solid #fff; }
      .custom-slider .MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px #796BE170; }
      .custom-slider .MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px #796BE170; }
      
      .MuiLinearProgress-root.custom-bar { background-color: #E9E9E9; }
      .custom-bar .MuiLinearProgress-bar { background-color: #EF8933; top: -1px; }

      // .css-eglki6-MuiLinearProgress-root { background: #d8d8d8; }
      // .css-5xe99f-MuiLinearProgress-bar1 { background: $color1; }
      // .css-eg0mwd-MuiSlider-thumb { background: $color1; }
      // .css-eg0mwd-MuiSlider-thumb:before { background: #fff; border: 4px solid $color1; width: 14px; height: 14px; }
      // .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible { box-shadow: 0px 0px 0px 8px #796BE170; }
      // .css-eg0mwd-MuiSlider-thumb:hover { box-shadow: 0px 0px 0px 8px #796BE170; }
    }
    .multiple-choice-container {
      .multiple-choice {
        & { display: flex; flex-direction: column; justify-content: center; align-items: center; gap: 10px; width: 550px; margin: 0 auto; min-height: 164px; }
        .answer {
          & { min-width: 400px; width: 100%; min-height: 45px; border-radius: 5px; background-color: rgba(242, 240, 240, 0.75); display: flex; justify-content: initial; align-items: center; color: #000; font-family: $fontLato; font-size: 18px; font-weight: 400; line-height: 25px; cursor: pointer; }
        }
        .selected { background-color: #EF8933; color: #FFF; font-weight: 700; line-height: 25px; }
        .tick-icon { color: #293745; border-radius: 50%; margin-right: 10px; margin-left: 5px; }
        span { white-space: nowrap; }
      }
    }

    .select-container {
      & { text-align: left; margin: 15px auto; width: 95%; max-width: 256px; color: #000; cursor: pointer !important; }
      .MuiInputBase-root { & { background-color: #F3F3F3; width: 100%; border: 1px solid #F3F3F3; cursor: pointer !important; } 
        .MuiOutlinedInput-notchedOutline { border: none; cursor: pointer !important; }
        .MuiSelect-select { padding: 10px 14px;}
      }
      
    }
  

    .slider-none-adjust { height: 35px; position: absolute; top: 2px; }

  } 
  .step-buttons {
    & { display: flex; justify-content: space-between; align-items: center; margin: 0px auto 0; max-width: 80%; padding: 42px; box-sizing: border-box; }
    button { padding: 12px 25px; font-family: $fontLato; font-size: 18px; letter-spacing: 0.762px; cursor: pointer; }
    .next-btn { background-color: #EF8933; color: #fff; font-weight: 700; min-width: 109px; height: 50px; border-radius: 25px; }
    .prev-btn { padding: 0; }
    .next-btn:hover { background-color: #293745; color: #FFF; }
    .prev-btn:hover { background-color: #fff; color: #FFF; }
  }

  .not-applicable-container { & { display: flex; justify-content: center; margin: 15px auto; } 
    p { font-weight: 400; }
  }
  
}

@media only screen and (max-width: 830px) {

  .assessment .steps {
    .progress-percent-text { display: none; }
    .question { font-size: 21px; }
    .progress-bar { 
      // & { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 1rem; }
      & { display: flex; justify-content: center; }
      .bar-section { width: 90% !important; }
    }
    .current-section { margin: 10px auto 0; }
    .progress-indicator { margin: 50px auto; }
    .question { align-items: flex-start; }
    .slider-container .slider-labels { & { font-size: 16px; }
      .label { & { max-width: 10ch; }
        p { line-height: 21px; letter-spacing: normal; }
      }
    }
    .radio-container .slider-labels { & { font-size: 16px; }
      // .label { max-width: 10ch; }
    }
  }

  .assessment .steps .progress-bar .bar-section .section-title { font-size: 16px; }

}

@media only screen and (max-width: 650px) {

  // .assessment .steps, main .assessment-additional .steps { width: 90%; padding: 0 5%; min-height: 400px; margin: 0 auto 30px; }
  .assessment .mobile-spacer { display: none; }
  .assessment .steps .progress-bar {
    .bar-section .progress-percentage { font-size: 1rem; margin: 1rem 0 0.5rem 0.75rem; }
    .sections .section-name { font-size: 15px; }
  }

}

@media only screen and (max-width: 550px) {

  .assessment { margin-top: 15px; min-height: 440px; }
  .assessment .steps { width: 95%; }
  .assessment .steps .slider-container .slider-points .point { margin-top: -3px; }

  main .assessment .steps {
    .question { min-height: 150px !important; font-size: 18px; line-height: 24px; max-width: 100%; display: flex; justify-content: center; align-items: center; padding-bottom: 0; }
    .progress-bar .bar-section .section-title { font-size: 12px;  }
    .slider-container { margin: 52px auto 66px; padding-bottom: 90px; }
    .radio-container { margin: 20px auto 42px; min-height: auto; }
    .slider-container .slider-labels .label { width: 14%; }
    .progress-indicator { margin: 15px auto 5px; margin-top: 0; color: #747474; text-align: center;  font-family: $fontLato; font-size: 12px; font-style: normal; line-height: 15px; text-transform: uppercase; }
    .question p { color: #343333; text-align: center; font-family: $fontLato; font-size: 18px; font-style: normal; font-weight: 400; line-height: 24px; width: 90%; margin-bottom: 0; margin-top: 20px; }
    .question-no-4 p { max-width: 26ch !important; }
    .progress-bar { grid-gap: 0; }
    .progress-bar .bar-section .bar { height: 6px; margin: 0 5px 0; }
    .progress-bar .bar-section .section-title { font-size: 12px; }
    .slider-container .slider-labels { font-size: 15px; }
    .slider-container .slider-labels .label p.desktop { display: none; }
    .slider-container .slider-labels .label p.mobile { display: block; font-size: 12px; }
    .radio-container .slider-labels { font-size: 12px; }
    .radio-container .slider-labels .label p.desktop { display: none; }
    .radio-container .slider-labels .label p.mobile { display: block; }
  
  }
  

  // .assessment .steps .slider-container .custom-slider.MuiSlider-root { width: calc(100% + 25px); margin-left: -6px; }
  .assessment .steps .slider-container .slider-labels .label p { width: 145%; margin-left: -75%; letter-spacing: 0px; line-height: normal; }

  .assessment .steps .progress-bar .bar-section .section-title { font-size: 14px; margin-bottom: 31px; }

}

@media only screen and (max-width: 830px) {
  .assessment {
    .step-buttons { max-width: 100%; margin: 30px auto 15px; padding: 15px 30px; }
    .step-buttons button { padding: 12.5px 2px; }
  }

}