
.start { & { min-height: 550px; background: #ffffff; display: flex; justify-content: center; }
  .video {
    // & { padding: 0.5rem; border-radius: 8px 0 0 8px; position: relative; width: auto; height: 100%; display: flex; justify-content: center; object-fit: cover; transition: all 0.25s; overflow: hidden; }
    // video { margin: 0 auto; }
    & { width: 50%; height: auto; display: flex; justify-content: center; position: relative; border-radius: 10px 0 0 10px; background-color: #00252A; object-fit: cover; transition: all 0.25s; overflow: hidden; }
    video { margin: 0px auto; position: relative; width: 100%; height: auto; }
    .thumbnail { position: absolute; height: 550px; max-height: 550px; overflow: hidden; }
    .playbtn { position: absolute; /* top: 85%; */ top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 90px; width: auto; border-radius: 0; }
    // .playbtn { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); cursor: pointer; height: 144px; width: 144px; }
    .custom-controls { & { position: absolute; box-sizing: border-box; bottom: 10px; max-height: 45px; width: 100%; max-width: 530px; left: 50%; top: 87.5%; transform: translate(-50%, -50%); background-color: rgba(0, 0, 0, 0.5); padding: 5px; display: flex; align-items: center; justify-content: space-between; gap: 10px; z-index: 2; border-radius: 30px; }
      button { background-color: transparent; border: none; color: white; cursor: pointer; align-self: center; padding: 5px; display: flex; justify-content: center; align-items: center; }
      input[type='range'] { width: 85%; height: 2px; -webkit-appearance: none; }
      input[type=range]::-webkit-slider-runnable-track { border-radius: 30px; }
      input[type=range]:hover::-webkit-slider-runnable-track { background-color: #fff; cursor: pointer; opacity: 0.95; }
      input[type='range']::-webkit-slider-thumb { -webkit-appearance: none; background-color: $color1; border: none; width: 10px; height: 10px; border-radius: 50%; }
      span { color: white; font-size: 14px; min-width: 8ch; min-width: 70px; text-align: left; }

    }
  }
  .intro {
    & { box-sizing: border-box; width: 50%; padding: 0 1% 0 2%; display: flex; flex-direction: column; justify-content: space-around; transition: all 0.25s; background-color: #FFFFFF; }
    h1 { font-family: $fontLato; font-weight: 700; font-size: 36px; color: #343333; line-height: 45px; width: auto; margin: 0px auto 0px 0; text-align: left; padding-top: 10px; max-width: 25ch; }
    .intro-para { font-weight: 600; font-size: 24px; line-height: 35px; margin: 10px auto; color: #343333; text-align: left; }
    h2 { margin: 0; margin-bottom: 0; font-weight: 400; font-style: normal; color: #747474; font-size:20px; line-height: 28px; ; max-width:401px; }
    .intro-points-list { & { padding-left: 0px; margin: 0px auto 0px 0; font-weight: 400; font-style: normal; color: #343333; font-size: 21px; line-height: 35px; max-width: 100%; }
      li { margin: 0px auto; }
      p { font-family: $fontLato; }
    }
    .completion { 
      & { width: 100%; display: flex; justify-content: space-between; align-items: center; text-align: center; margin: 0px auto 0px; color: #343333; font-weight: 700; font-size: 18px; line-height: 27px; }
      .cta { font-family: $fontLato; width: 100%; max-width: 215px; height: 50px; padding: 7px 10px; margin-left: 0; /* transition: 0.3s; */ border-radius: 25px; text-transform: uppercase; font-weight: 700; font-size: 21px; letter-spacing: 1px; }
      .cta:hover { background-color: #293745; transition: 0.3s; }
      .time-estimate {
        & { display: flex; justify-content: center; margin: 15px auto 15px 30px; align-items: center; }
        .clock-icon { width: 25px; height: 25px; margin-top: 7px; padding-right: 3px; }
         span { color: #343333; font-family: $fontLato; font-size: 21px; font-style: normal; font-weight: 400; line-height: 35px; }
        
      }
    }
    .para-container{ & {width: auto; text-align: center; }
      .para { margin: 0; padding: 0; font-size: 12px; font-weight: 300; line-height: 21px; color: #343333; padding: 0px; font-family: $fontLato; text-align: left; }
    }
  }
    
  // .para-container {
  //   p { color: #5A5A5A; font-size: 24px; font-weight: 400; margin: 0.5rem; text-align: center; }
  // }
  //   .completion { & { display: flex; flex-direction: row; align-items: center; color: #4e4229; font-weight: 400; font-size: 18px; padding: 1.5rem 0rem; }
  //   }
  //   .btn-container{ & { padding-bottom: 2rem; }
  //   .cta { display: block; background: #348CFE; border-radius: 25px; color: #fff; text-decoration: none; width: 193px; text-align: center; font-family: $primaryfont; font-size: 20px; font-weight: 700; padding: 12px 0 12px; margin-left: 0; }
  //     .cta:hover { background: #fcb900; transition: 0.3s; color: #fff; }
  // }
  //     .time-estimate {
  //       & { display: flex; margin: 0; background-color: #FDE6A2; padding: 0.3rem 0.75rem 0.3rem 0.75rem; border-radius: 30px; }
  //       .clock-icon { width: 22px; height: 22px; }
  //       .img-container { padding-top: 0.1rem; padding-right: 0.25rem ; padding-left:0.5rem; }
  //     }
    
  }


.container.start { width: 95%; } 



@media only screen and (max-width: 830px) {
  
  .container.start { margin: 15px auto; background-color: #293745; }
  .start {
    & { flex-direction: column; height: auto; width: 95%; }
    .video { & { width: 100%; box-sizing: border-box; height: auto; border-radius: 10px; }
      .custom-controls { max-width: 90%; height: 30px; top: 90%; }
      .playbtn { /* top: 82%; */ height: 60px; }
    }
    .intro { 
      & { background-color: #FFFFFF; border-radius:10px; width: 100%; box-sizing: border-box; margin: 7px auto; padding: 15px; height: auto; }
      h1 { text-align: center; width: auto; font-size: 28px; margin: 0 auto 15px; max-width: 26ch; padding-top: 30px; }
      h2 { text-align: center; width: 100%; max-width: 34ch; margin: auto; }
      .intro-para { text-align: center; max-width: 35ch; margin: 5px auto; font-size: 21px; }
      .intro-points-list { width: auto; max-width: 34ch; margin: 0px auto; padding-left: 20px; }
      .completion { 
        & { flex-direction: column; margin: 30px auto 0 auto; }
        .time-estimate { margin: 15px auto; }
        .cta { margin-bottom: 0; }          
      }
    }
  }

  .start .video video { width: 100%; } //margin: 0 -10%; object-fit: cover; }
  .start .video .thumbnail { width: 100%; height: auto; object-fit: cover; position: relative; }

}

@media only screen and (max-width: 1000px) and (min-width: 831px) {
  
  .start .intro {
    h1 { font-size: 28px; line-height: 40px; text-align: left; }
    .intro-para { font-size: 22px; line-height: 30px; }
    .intro-points-list { font-size: 18px; }
    
    .completion { margin-top: 15px; }
    .completion .cta { font-size: 18px; max-width: 215px; }
  }

}

@media only screen and (min-width: 831px) {
  
}

@media only screen and (max-width: 1110px) {

}


@media only screen and (max-width: 650px) {
  
  .start { & {}
    .video .playbtn { height: 50px; /* top: 85%; */ }
    .intro {
      h1 { text-align: center; }
      h2 { text-align: center; font-size: 1.25rem; line-height: 25px; }
      // h1 br { content: ""; }
      h1 br:after { content: " "; }
    }
  }

}

@media only screen and (max-width: 550px) {

  .start { margin-top: 15px; }
  .start .video { & { height: 57vw; width: 100%; border-radius: 10px; }
    video { width: auto; }
    .close-video { right: 5px !important; top: 5px !important; }
  }
  .start .intro { & {  }
    h1 { font-size: 21px; line-height: 25px; max-width: 26ch; margin: 0 auto 15px; font-weight: 700; padding-top: 5px; margin-bottom: 0; padding-bottom: 0;}
    h2 { font-size: 17px; max-width: 34ch; }
    .intro-para { font-size: 18px; line-height: 24px; max-width: 34ch; }
    .intro-points-list { margin-top: 0; padding-top: 0; font-size: 16px; font-weight: 400; line-height: 31px; max-width: 36ch; padding-left: 5px;
      p { margin-top: 14px; text-align: center; max-width: 30ch; }
    }
    .completion img { margin: 0 4px 0 0; }
    .para-container .para { text-align: center; line-height: 21px; margin-top: 12px;}
  }

  .start .intro .completion { & { margin: 10px auto 0; width: 100%; justify-content: center; }
    .time-estimate { 
      & {margin-bottom: 0;}
      span { font-weight: 700; font-size: 18px; }
    }
  }
  .start .intro .completion .cta { margin: 0 auto; width: 100%; }
}
